// CLEAR FIX
@mixin clearfix {

	&:after {
		display: table;
		clear: both;
		content: '';
	}

}

// Animation

@mixin transition($type-of-transition...){
	transition: $type-of-transition;
}

@mixin default-animation($type...) {
	transition-delay: 0s;
	transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	transition-duration: 0.28s;
	transition-property: $type;
	will-change: $type;
}

// Box Shadow
@mixin box-shadow($x, $y, $blur, $color, $spread, $inset: false) {

	@if $inset {
		box-shadow: inset $x $y $blur $spread $color;
	} @else {
		box-shadow: $x $y $blur $spread $color;
	}

}

%clearfix {
	@include clearfix;
}

%elevation1 {
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.2);
}

%elevation2 {
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.36);
}

%elevation3 {
	box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12),0 3px 5px -1px rgba(0,0,0,0.4);
}

%elevation4 {
	box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12),0 5px 5px -3px rgba(0,0,0,0.4);
}

%elevation5 {
	box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.4);
}

%list-crd {
	box-shadow: 0 14px 28px rgba(0,0,0,0.18), 0 10px 10px rgba(0,0,0,0.12);
}

%shadow1 {
	box-shadow: 0 2px 5px 0 -1px rgba(0,0,0,0.30);
}

%shadow2 {
	box-shadow: 0 5px 8px 0 -3px rgba(0,0,0,0.40);
}

%shadow {
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.36);
}

%shadow-hover {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.36);
}

@mixin crd-effect($state) {
	@if($state == normal) {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			opacity: 0;
			border-radius: $border-radius-base;
			@extend %list-crd;
			@include default-animation(opacity);
		}
	}

	@else if ($state == hover) {
		&:before {
			opacity: 1;
		}

	}

	@else if ($state == none) {
		&:before {
			opacity: 0;
		}
	}

}

// Mixin creates hidden element for different breakpoints
@mixin hidden($type, $size) {
	@if $type == to {
		@media (max-width: #{map-get(map-get($hdn-breakpoint, $type), $size)}) {
			display:none !important;
		}
	}@else {
		@media (min-width: #{map-get(map-get($hdn-breakpoint, $type), $size)}) {
			display:none !important;
		}
	}
}

// Ellipsi, default mixin,do not add more properties
@mixin ellipsis {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

%o-flex1--ellipsis,
%o-flex0--ellipsis  {
	min-width: 0;
}

%abs-cent-el {
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

%abs-cent-rgh-el {
	position: absolute;
	top: 50%;
	right: -10px;
	transform: translate(-50%, -50%);
}

%abs-cent-left-el {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

%ripple-effect {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: auto;
		transform: scale(0);
		@include default-animation(transform);
	}
}

%gutter {
	padding: 0 18px;
}

%no-select {
	user-select: none;
}

@mixin button-variant ($color, $bg, $border) {
	color: $color;
	background: $bg;
	border-color: $border;
}

@mixin divider($color: $divider-color, $margin-y: $divider-margin-y) {
	margin: $margin-y 0;
	height: 0;
	overflow: hidden;
	border-top: 1px solid $color;
}

@mixin hover-focus {
	&:hover ,
	&:focus {
		@content;
	}
}

@mixin gradient-bg($color) {
	@if $enable-gradients {
		background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
	} @else {
		background-color: $color;
	}
}

@mixin box-sizing($boxmodel) {
	box-sizing: $boxmodel;
}

@mixin img-responsive($display: block) {
	display: $display;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Mixin for disabled select we use it on buttons
@mixin no-select {
	user-select: none;
}

// Mixin for create unstyled list
@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
}

@mixin list-reset {
	margin:0;
	padding: 0;
	list-style: none;
}

//## DATE-RANGE
/**
 * Mixin used to style inline datetime range on training history and create-reports
 * Should be removed when datepicker component get refacotred
 */
@mixin date-range() {

	lu-datetime-picker {
		display: flex;
	}
	.datetime-picker {
		$this: &;
		flex: 1 1 auto;

		&:first-child {
			margin-right: 6px;
		}

		&:last-child {
			margin-left: 6px;
		}

		& #{$this}__grp {
			margin-bottom: 0;
		}
	}

}
