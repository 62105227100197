$node-types: (
	course: $workflow-blue,
	branch: $workflow-purple,
	certificate: $workflow-orange,
	delay: $workflow-red
);

$shades: (
	course-pale: $workflow-blue-pale,
	branch-pale: $workflow-purple-pale,
	certificate-pale: $workflow-orange-pale,
	delay-pale: $workflow-red-pale
);

$expression-categories: (
	undefined: $gray-pale,
	previous_enrollment: $workflow-green,
	custom_user_data: $workflow-purple,
);

$expression-categories-expanded: (
	previous_enrollment: $workflow-action-green,
	custom_user_data: $workflow-secondary-brand-purple,
);

$expression-categories-expanded-outlines: (
	previous_enrollment_outline: $workflow-action-green-outline,
	custom_user_data_outline: $workflow-secondary-brand-purple-outline,
);

/**
 *  Mixin used to print colors for all types of Nodes
 */

/** Arguments for mixin
	- $property node, header, icon
*/
@mixin nodeColor($property) {
	@each $type, $color in $node-types {

		@if $property == node {
			&--#{$type} {
				border-color: $color;
			}
		}

		@else if $property == header {
			&--#{$type} {
				background: map-get($shades, #{$type}-pale);
			}
		}

		@else if $property == icon {
			&--#{$type} {
				--icon-fill: #{$color};
			}
		}
	}
}

/**
 *  Mixin used to print colors for all expression types
 */
@mixin expressionCategoryColors() {
	@each $type, $color in $expression-categories {

		&--#{$type} {
				background-color: #{$color};
		}
	}
}

/**
 *  Mixin used to print colors for expanded branch view
 */
@mixin expressionCategoryColorsExpanded() {
	@each $type, $color in $expression-categories-expanded {

		&--#{$type} {
			background-color: #{$color};
			border: 1px solid map-get($expression-categories-expanded-outlines, #{$type}_outline);
		}
	}
}
