
// [!] GENERATED CODE - DO NOT EDIT THIS FILE

//
// Design Tokens
//

:root {

    color-scheme: only light;



	/* Primitives/Mode 1 */
	--colours-neutrals-white-negative: #ffffff; 
	--colours-neutrals-gray-900: #0a1926; 
	--colours-neutrals-gray-800: #333d46; 
	--colours-neutrals-gray-700: #5f6871; 
	--colours-neutrals-gray-600: #a5acb3; 
	--colours-neutrals-gray-500: #c7ccd1; 
	--colours-neutrals-gray-400: #dadfe3; 
	--colours-neutrals-gray-300: #e4e7eb; 
	--colours-neutrals-gray-200: #f7f8fa; 
	--colours-neutrals-gray-100: #f0f2f5; 
	--colours-extended-palette-greens-green-900: #1f8551; 
	--colours-extended-palette-greens-green-700: #44d48b; 
	--colours-extended-palette-greens-green-500: #bdf0d6; 
	--colours-extended-palette-greens-green-300: #dcf9ea; 
	--colours-extended-palette-greens-green-800---tbc: #00b187; 
	--colours-extended-palette-cyans-cyan-900: #0578ad; 
	--colours-extended-palette-cyans-cyan-700: #07a3eb; 
	--colours-extended-palette-cyans-cyan-500: #b0e0fd; 
	--colours-extended-palette-cyans-cyan-300: #d8f0fd; 
	--colours-extended-palette-oranges-orange-900: #a34815; 
	--colours-extended-palette-oranges-orange-700: #ff6f3f; 
	--colours-extended-palette-oranges-orange-500: #ffbea8; 
	--colours-extended-palette-oranges-orange-300: #ffe2d6; 
	--colours-extended-palette-purples-purple-900: #af1d8b; 
	--colours-extended-palette-purples-purple-700: #e045ba; 
	--colours-extended-palette-purples-purple-500: #f6c6ea; 
	--colours-extended-palette-purples-orchid-300: #fbdff4; 
	--colours-extended-palette-aquas-aqua-900: #00545c; 
	--colours-extended-palette-aquas-aqua-700: #00becf; 
	--colours-extended-palette-aquas-aqua-500: #a8f8ff; 
	--colours-extended-palette-aquas-aqua-300: #daf8fb; 
	--colours-extended-palette-teals-teal-900: #0d8074; 
	--colours-extended-palette-teals-teal-700: #15d0bd; 
	--colours-extended-palette-teals-teal-500: #bbede9; 
	--colours-extended-palette-teals-teal-300: #e2f8f6; 
	--colours-extended-palette-yellows-yellow-900: #a55202; 
	--colours-extended-palette-yellows-yellow-700: #ffb706; 
	--colours-extended-palette-yellows-yellow-500: #ffe299; 
	--colours-extended-palette-yellows-honey-300: #fff5db; 
	--colours-extended-palette-violets-violet-900: #8738ff; 
	--colours-extended-palette-violets-violet-700: #a77aff; 
	--colours-extended-palette-violets-violet-500: #ccb2ff; 
	--colours-extended-palette-violets-violet-300: #eee5ff; 
	--colours-extended-palette-blues-blue-900: #316bf1; 
	--colours-extended-palette-blues-blue-700: #669bf1; 
	--colours-extended-palette-blues-blue-500: #99cbf1; 
	--colours-extended-palette-blues-future-300: #cce6ff; 
	--colours-extended-palette-reds-red-900: #a31515; 
	--colours-extended-palette-reds-red-700: #eb3030; 
	--colours-extended-palette-reds-red-500: #ff7a7a; 
	--colours-extended-palette-reds-red-300: #fbd0d0; 
	--colours-brand-primary: #356df1; 
	--colours-brand-secondary: #082645; 
	--iconsize-l: 2.25rem; 
	--iconsize-md: 1.5rem; 
	--iconsize-s: 1.25rem; 
	--iconsize-xs: 1.125rem; 
	--iconsize-xxs: 1rem; 


	/* Tokens/Mode 1 */
	--object-object-primary: #0a1926; // Reference: Colours.Neutrals.Gray 900
	--object-object-secondary: #333d46; // Reference: Colours.Neutrals.Gray 800
	--object-object-tertiary: #5f6871; // Reference: Colours.Neutrals.Gray 700
	--object-object-disabled: #a5acb3; // Reference: Colours.Neutrals.Gray 600
	--strokes-stroke-active: #a5acb3; // Reference: Colours.Neutrals.Gray 600
	--strokes-stroke-primary: #c7ccd1; // Reference: Colours.Neutrals.Gray 500
	--strokes-stroke-secondary: #dadfe3; // Reference: Colours.Neutrals.Gray 400
	--surfaces-surface-tertiary: #e4e7eb; // Reference: Colours.Neutrals.Gray 300
	--surfaces-surface-primary: #f7f8fa; // Reference: Colours.Neutrals.Gray 200
	--surfaces-surface-secondary: #f0f2f5; // Reference: Colours.Neutrals.Gray 100
	--surfaces-surface-light: #ffffff; // Reference: Colours.Neutrals.White-Negative
	--surfaces-surface-active: #c7ccd1; // Reference: Colours.Neutrals.Gray 500
	--semantic-progress: #316bf1; // Reference: Colours.Extended Palette.Blues.Blue 900
	--semantic-success: #44d48b; // Reference: Colours.Extended Palette.Greens.Green 700
	--semantic-warning: #ff6f3f; // Reference: Colours.Extended Palette.Oranges.Orange 700
	--semantic-alert: #eb3030; // Reference: Colours.Extended Palette.Reds.Red 700
	--semantic-progress-pale: #cce6ff; // Reference: Colours.Extended Palette.Blues.Future 300
	--semantic-success-pale: #dcf9ea; // Reference: Colours.Extended Palette.Greens.Green 300
	--semantic-warning-pale: #ffe2d6; // Reference: Colours.Extended Palette.Oranges.Orange 300
	--semantic-alert-pale: #fbd0d0; // Reference: Colours.Extended Palette.Reds.Red 300
	--semantic-progress-dark: #082645; // Reference: Colours.Brand.Secondary
	--semantic-success-dark: #00545c; // Reference: Colours.Extended Palette.Aquas.Aqua 900
	--semantic-warning-dark: #a34815; // Reference: Colours.Extended Palette.Oranges.Orange 900
	--semantic-alert-dark: #a31515; // Reference: Colours.Extended Palette.Reds.Red 900
	--spacing-spacing-02: 0.125rem; 
	--spacing-spacing-04: 0.25rem; 
	--spacing-spacing-08: 0.5rem; 
	--spacing-spacing-12: 0.75rem; 
	--spacing-spacing-16: 1rem; 
	--spacing-spacing--24: 1.5rem; 
	--spacing-spacing-40: 2.5rem; 
	--spacing-spacing-48: 3rem; 
	--spacing-spacing-64: 4rem; 
	--spacing-spacing-96: 6rem; 
	--spacing-spacing-32: 2rem; 
	--corners-s: 0.25rem; 
	--corners-m: 0.5rem; 
	--corners-xs: 0.125rem; 
	--corners-l: 0.75rem; 
	--corners-xl: 1.125rem; 
	--bg-blur: 0.5rem; 


	/* primitives-tokens/Mode 1 */
	--primitive-colours-neutrals-white: #ffffff; 
	--primitive-colours-neutrals-gray-800: #333d46; 
	--primitive-colours-neutrals-gray-700: #5f6871; 
	--primitive-colours-neutrals-gray-600: #a5acb3; 
	--primitive-colours-neutrals-gray-500: #c7ccd1; 
	--primitive-colours-neutrals-gray-400: #dadfe3; 
	--primitive-colours-neutrals-gray-300: #e4e7eb; 
	--primitive-colours-neutrals-gray-200: #f0f3f5; 
	--primitive-colours-neutrals-gray-100: #f7f9fa; 
	--primitive-colours-neutrals-gray-900: #0a1826; 
	--primitive-colours-greens-green-400: #1f8551; 
	--primitive-colours-greens-green-300: #44d48a; 
	--primitive-colours-greens-green-200: #bdf0d6; 
	--primitive-colours-greens-green-100: #dcf9ea; 
	--primitive-colours-cyans-cyan-400: #0578ad; 
	--primitive-colours-cyans-cyan-300: #07a3eb; 
	--primitive-colours-cyans-cyan-200: #b0e5fd; 
	--primitive-colours-cyans-cyan-100: #d8f1fd; 
	--primitive-colours-oranges-orange-400: #a33915; 
	--primitive-colours-oranges-orange-300: #ff6f3f; 
	--primitive-colours-oranges-orange-200: #ffbea8; 
	--primitive-colours-oranges-orange-100: #ffe0d6; 
	--primitive-colours-purples-purple-400: #af1d8b; 
	--primitive-colours-purples-purple-300: #e045ba; 
	--primitive-colours-purples-purple-200: #f6c6ea; 
	--primitive-colours-purples-purple-100: #fbdff4; 
	--primitive-colours-aquas-aqua-400: #00545c; 
	--primitive-colours-aquas-aqua-300: #00becf; 
	--primitive-colours-aquas-aqua-200: #a8f8ff; 
	--primitive-colours-aquas-aqua-100: #daf8fb; 
	--primitive-colours-teals-teal-400: #0d8074; 
	--primitive-colours-teals-teal-300: #15d0bd; 
	--primitive-colours-teals-teal-200: #bbede9; 
	--primitive-colours-teals-teal-100: #e2f8f6; 
	--primitive-colours-yellows-yellow-400: #a55202; 
	--primitive-colours-yellows-yellow-300: #ffb706; 
	--primitive-colours-yellows-yellow-200: #ffe299; 
	--primitive-colours-yellows-yellow-100: #fff5db; 
	--primitive-colours-violets-violet-400: #7a38ff; 
	--primitive-colours-violets-violet-300: #a77aff; 
	--primitive-colours-violets-violet-200: #ccb2ff; 
	--primitive-colours-violets-violet-100: #eee5ff; 
	--primitive-colours-violets-violet-500: #622dcc; 
	--primitive-colours-blues-blue-400: #356df1; 
	--primitive-colours-blues-blue-300: #6690f1; 
	--primitive-colours-blues-blue-200: #99b3f1; 
	--primitive-colours-blues-blue-100: #ccdbff; 
	--primitive-colours-blues-blue-500: #2356ce; 
	--primitive-colours-blues-blue-600: #1f4cb4; 
	--primitive-colours-blues-blue-900: #0e224f; 
	--primitive-colours-reds-red-400: #a31515; 
	--primitive-colours-reds-red-300: #eb3030; 
	--primitive-colours-reds-red-200: #ff7a7a; 
	--primitive-colours-reds-red-100: #fbd0d0; 
	--icon-size-l: 3.6rem; 
	--icon-size-md: 2.4rem; 
	--icon-size-s: 2rem; 
	--icon-size-xs: 1.6rem; 
	--icon-size-xxs: 1.2rem; 
	--spinner-size-l: 3.6rem; // Reference: icon-size.l
	--spinner-size-md: 2.4rem; // Reference: icon-size.md
	--spinner-size-s: 2rem; // Reference: icon-size.s
	--spinner-size-xs: 1.6rem; // Reference: icon-size.xs
	--spinner-size-xxs: 1.2rem; // Reference: icon-size.xxs


	/* semantic-tokens/Mode 1 */
	--semantic-colour-tokens-stroke-stroke-dark: #5f6871; // Reference: primitive-colours.Neutrals.Gray 700
	--semantic-colour-tokens-stroke-stroke-primary: #c7ccd1; // Reference: primitive-colours.Neutrals.Gray 500
	--semantic-colour-tokens-stroke-stroke-light: #e4e7eb; // Reference: primitive-colours.Neutrals.Gray 300
	--semantic-colour-tokens-stroke-stroke-on-dark-surface: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-stroke-stroke-brand: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-stroke-stroke-alert: #eb3030; // Reference: primitive-colours.Reds.Red 300
	--semantic-colour-tokens-stroke-stroke-tile-received: #ffb706; // Reference: primitive-colours.Yellows.Yellow 300
	--semantic-colour-tokens-surface-surface-dark: #0e224f; // Reference: primitive-colours.Blues.Blue 900
	--semantic-colour-tokens-surface-surface-primary: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-surface-surface-secondary: #f7f9fa; // Reference: primitive-colours.Neutrals.Gray 100
	--semantic-colour-tokens-surface-surface-brand-light: #ccdbff; // Reference: primitive-colours.Blues.Blue 100
	--semantic-colour-tokens-surface-surface-brand: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-surface-surface-overlay: #0a1826; // Reference: primitive-colours.Neutrals.Gray 900
	--semantic-colour-tokens-message-message-info-indicator: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-message-message-success-indicator: #44d48a; // Reference: primitive-colours.Greens.Green 300
	--semantic-colour-tokens-message-message-warning-indicator: #ff6f3f; // Reference: primitive-colours.Oranges.Orange 300
	--semantic-colour-tokens-message-message-error-indicator: #eb3030; // Reference: primitive-colours.Reds.Red 300
	--semantic-colour-tokens-message-message-info-background: #ccdbff; // Reference: primitive-colours.Blues.Blue 100
	--semantic-colour-tokens-message-message-success-background: #dcf9ea; // Reference: primitive-colours.Greens.Green 100
	--semantic-colour-tokens-message-message-warning-background: #ffe0d6; // Reference: primitive-colours.Oranges.Orange 100
	--semantic-colour-tokens-message-message-error-background: #fbd0d0; // Reference: primitive-colours.Reds.Red 100
	--semantic-colour-tokens-icons-icon-default: #5f6871; // Reference: primitive-colours.Neutrals.Gray 700
	--semantic-colour-tokens-icons-icon-info-on-light-surface: #1f4cb4; // Reference: primitive-colours.Blues.Blue 600
	--semantic-colour-tokens-icons-icon-warning-on-light-surface: #a33915; // Reference: primitive-colours.Oranges.Orange 400
	--semantic-colour-tokens-icons-icon-on-dark-surface: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-icons-icon-error-on-light-surface: #a31515; // Reference: primitive-colours.Reds.Red 400
	--semantic-colour-tokens-icons-icon-sucess-on-light-surface: #1f8551; // Reference: primitive-colours.Greens.Green 400
	--semantic-colour-tokens-icons-icon-success-on-dark-surface: #dcf9ea; // Reference: primitive-colours.Greens.Green 100
	--semantic-colour-tokens-icons-icon-error-on-dark-surface: #fbd0d0; // Reference: primitive-colours.Reds.Red 100
	--semantic-colour-tokens-icons-icon-rating: #ffb706; // Reference: primitive-colours.Yellows.Yellow 300
	--semantic-colour-tokens-icons-icon-brand: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-icons-icon-error: #eb3030; // Reference: primitive-colours.Reds.Red 300
	--semantic-colour-tokens-icons-icon-success: #44d48a; // Reference: primitive-colours.Greens.Green 300
	--semantic-colour-tokens-link-link-enabled: #2356ce; // Reference: primitive-colours.Blues.Blue 500
	--semantic-colour-tokens-link-link-active: #622dcc; // Reference: primitive-colours.Violets.Violet 500
	--semantic-colour-tokens-link-link-on-dark-surface: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-link-link-hover: #622dcc; // Reference: primitive-colours.Violets.Violet 500
	--semantic-colour-tokens-link-link-on-light-surface: #0a1826; // Reference: primitive-colours.Neutrals.Gray 900
	--semantic-colour-tokens-text-text-primary: #0a1826; // Reference: primitive-colours.Neutrals.Gray 900
	--semantic-colour-tokens-text-text-on-dark-surface: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-text-text-secondary: #333d46; // Reference: primitive-colours.Neutrals.Gray 800
	--semantic-colour-tokens-text-text-brand: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-text-text-error: #a31515; // Reference: primitive-colours.Reds.Red 400
	--semantic-colour-tokens-text-text-success: #1f8551; // Reference: primitive-colours.Greens.Green 400
	--semantic-colour-tokens-text-text-inputs: #5f6871; // Reference: primitive-colours.Neutrals.Gray 700
	--semantic-colour-tokens-buttons-button-primary: #356df1; // Reference: primitive-colours.Blues.Blue 400
	--semantic-colour-tokens-buttons-button-secondary-enabled: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-buttons-button-secondary-hover: #e4e7eb; // Reference: primitive-colours.Neutrals.Gray 300
	--semantic-colour-tokens-buttons-button-secondary-active: #c7ccd1; // Reference: primitive-colours.Neutrals.Gray 500
	--semantic-colour-tokens-buttons-button-flat: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-buttons-button-ghost-hover: #e4e7eb; // Reference: primitive-colours.Neutrals.Gray 300
	--semantic-colour-tokens-buttons-button-ghost-active: #c7ccd1; // Reference: primitive-colours.Neutrals.Gray 500
	--semantic-colour-tokens-buttons-button-inverse: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-filter-filter-default: #ffffff; // Reference: primitive-colours.Neutrals.White
	--semantic-colour-tokens-filter-filter-active: #f0f3f5; // Reference: primitive-colours.Neutrals.Gray 200
	--semantic-colour-tokens-filter-filter-brand: #ccdbff; // Reference: primitive-colours.Blues.Blue 100
	--semantic-colour-tokens-progress-progress-bar-background: #e4e7eb; // Reference: primitive-colours.Neutrals.Gray 300
	--semantic-colour-tokens-progress-progress-bar-default: #44d48a; // Reference: primitive-colours.Greens.Green 300
	--semantic-colour-tokens-progress-progress-bar-fail: #eb3030; // Reference: primitive-colours.Reds.Red 300
	--spacing-spacing-02: 0.2rem; 
	--spacing-spacing-04: 0.4rem; 
	--spacing-spacing-08: 0.8rem; 
	--spacing-spacing-12: 1.2rem; 
	--spacing-spacing-16: 1.6rem; 
	--spacing-spacing-24: 2.4rem; 
	--spacing-spacing-40: 4rem; 
	--spacing-spacing-48: 4.8rem; 
	--spacing-spacing-64: 6.4rem; 
	--spacing-spacing-96: 9.6rem; 
	--spacing-spacing-32: 3.2rem; 
	--corners-s: 0.4rem; 
	--corners-m: 0.8rem; 
	--corners-xs: 0.2rem; 
	--corners-l: 1.2rem; 
	--corners-xl: 1.8rem; 
	--bg-blur: 0.8rem; 

}
  
