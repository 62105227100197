//##  COLORS

// Neutrals - White, black or gray schemes
$black: var(--object-object-primary);
$gray-darker: var(--object-object-secondary);
$gray-dark: var(--object-object-tertiary);
$gray: var(--strokes-stroke-active);
$gray-base: var(--strokes-stroke-primary);
$gray-light: var(--strokes-stroke-secondary);
$gray-pale: var(--surfaces-surface-tertiary);
$gray-lighter: var(--surfaces-surface-secondary);
$white: var(--surfaces-surface-light);
$gray-niceice: $gray-lighter; // TODO remove alias
$brand-primaryfaded: $gray-lighter; // TODO remove alias

// TODO Remove all brand colors. See https://www.figma.com/design/m8LnZHoXcohvHxcFTYwJQL/LearnUpon-Design-System?node-id=58259-146721&m=dev
// Brand colors
$brand-primary: #097bb8 !default;
$brand-success: #288008 !default;
$brand-info: #097bb8 !default;
$brand-warning: #d98746 !default;
$brand-danger: #C24E46 !default;
$brand-red: #EB0000 !default;

//## Semantic Colors

// Base semantic colors
$semantic-progress: var(--semantic-progress);
$semantic-positive: var(--semantic-success);
$semantic-warning: var(--semantic-warning);
$semantic-alert: var(--semantic-alert);

// Dark variations - semantic colors
$semantic-progress-dark: var(--semantic-progress-dark);
$semantic-positive-dark: var(--semantic-success-dark);
$semantic-warning-dark: var(--semantic-warning-dark);
$semantic-alert-dark: var(--semantic-alert-dark);

// Pale variations - semantic "transparent" colors
$semantic-progress-pale: var(--semantic-progress-pale);
$semantic-positive-pale: var(--semantic-success-pale);
$semantic-warning-pale: var(--semantic-warning-pale);
$semantic-alert-pale: var(--semantic-alert-pale);
$semantic-default-pale: $gray-pale;

// Pale variations - semantic solid colors
$semantic-progress-pale-solid: var(--semantic-progress-pale);
$semantic-positive-pale-solid: var(--semantic-success-pale);
$semantic-warning-pale-solid: var(--semantic-warning-pale);
$semantic-alert-pale-solid: var(--semantic-alert-pale);

// Action colors
$action-green: $semantic-positive;
$transparent: transparent;
$my-profile-gray: $gray-light;
$empty-card-gray: $gray-light;


//##  DROPDOWNS

// Hover color for dropdown links.
$dropdown-link-hover-color: color-mix(in srgb, $gray-dark, black 5%);


// ## PILLS
$pill-plain: var(--surfaces-surface-light);
$pill-base: var(--surfaces-surface-tertiary);
$pill-success: var(--semantic-success);
$pill-success-light: var(--semantic-success-pale);
$pill-danger: var(--semantic-alert);
$pill-danger-light: var(--semantic-alert-pale);
$pill-warning: var(--semantic-warning);
$pill-warning-light: var(--semantic-colour-tokens-message-message-warning-background);
$pill-info: var(--colours-extended-palette-violets-violet-700);
$pill-progress: var(--semantic-progress);
$pill-progress-light: var(--semantic-progress-pale);
$pill-pending: var(--colours-extended-palette-yellows-honey-300);